export default {
  data() {
    return {
      metaTitleTemplate: '%s | Texas Farm Bureau Insurance',
      siteName: 'Texas Farm Bureau Insurance',
      twitterHandle: '@TXFBInsurance',
      defaultMetaDescription: 'Protecting Texans for over 50 years, Texas Farm Bureau Insurance makes insurance effortless so you can focus on the moments truly worth covering.',
      defaultMetaKeywords: 'texas farm bureau insurance, texas auto insurance, texas home/property insurance, texas life insurance, texas health insurance, texas farm insurance, '
        + 'texas ranch insurance, texas protection, texas insurance, texas farm bureau, texas farm bureau about us, texas farm bureau protection, texas farm bureau insurance customers, '
        + 'texas farm bureau, home insurance, auto insurance',
      defaultHeaderImage: `${process.env.VUE_APP_ROOT_URI}/publishingimages/default-rural-banner.png`,
    };
  },
  metaInfo() {
    const metaArray = [
      { vmid: 'description', name: 'description', content: this.metaDescription ? this.metaDescription : this.defaultMetaDescription },
      // Facebook / OpenGraph data
      { property: 'og:title', content: this.metaPageTitle ? `${this.metaPageTitle} | ${this.siteName}` : this.siteName },
      { property: 'og:type', content: this.metaType ? this.metaType : 'website' },
      { property: 'og:url', content: this.metaUrl },
      { property: 'og:image', content: this.headerImage && this.headerImage !== '' ? this.headerImage : this.defaultHeaderImage },
      { property: 'og:description', content: this.metaDescription ? this.metaDescription : this.defaultMetaDescription },
      { property: 'og:site_name', content: this.siteName },
      { property: 'og:locale', content: 'en_US' },
      // { property: 'fb:admins', content: 'Facebook numberic ID' },
      // Twitter card (will fallback to Open Graph)
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:site', content: this.twitterHandle },
      { name: 'twitter:title', content: this.metaPageTitle ? `${this.metaPageTitle} | ${this.siteName}` : this.siteName },
      { name: 'twitter:description', content: this.metaDescription ? this.metaDescription : this.defaultMetaDescription },
      { name: 'twitter:creator', content: this.twitterHandle },
      { name: 'twitter:image:src', content: this.headerImage ? this.headerImage : this.defaultHeaderImage },
      // Google / Schema.org markup:
      { itemprop: 'name', content: this.siteName },
      { itemprop: 'description', content: this.metaDescription ? this.metaDescription : this.defaultMetaDescription },
      { itemprop: 'image', content: this.headerImage ? this.headerImage : this.defaultHeaderImage },
    ];

    if (this.metaKeywords && this.metaKeywords !== '') {
      metaArray.push({ property: 'keywords', content: this.metaKeywords });
    }

    if (this.articlePublishTime && this.articlePublishTime !== '') {
      metaArray.push({ property: 'article:published_time', content: this.articlePublishTime });
    }

    if (this.articleModifiedTime && this.articleModifiedTime !== '') {
      metaArray.push({ property: 'article:modified_time', content: this.articleModifiedTime });
    }

    if (this.articleSection && this.articleSection !== '') {
      metaArray.push({ property: 'article:section', content: this.articleSection });
    }

    if (this.articleTags && this.articleTags.length > 0) {
      for (let t = 0; t < this.articleTags.length; t += 1) {
        metaArray.push({ property: 'article:tag', content: this.articleTags[t] });
      }
    }

    // If the metaNoIndex is set to a value or this is an environment other than Production, add
    //  meta values to prevent indexing/following
    if ((this.metaNoIndex && this.metaNoIndex !== '') || (process.env.VUE_APP_WEB_ENV !== 'PROD' && process.env.VUE_APP_WEB_ENV !== 'DEV')) {
      metaArray.push({ name: 'robots', content: 'noindex,nofollow' });
      metaArray.push({ name: 'googlebot', content: 'noindex,nofollow' });
    } else {
      metaArray.push({ name: 'robots', content: 'index,follow' });
      metaArray.push({ name: 'googlebot', content: 'index,follow' });
    }

    return {
      title: this.metaPageTitle,
      titleTemplate: this.metaTitleTemplate,
      htmlAttrs: {
        lang: 'en',
      },
      link: [
        { rel: 'canonical', href: this.metaUrl },
      ],
      meta: metaArray,
    };
  },
};
