import { make } from 'vuex-pathify';
import axios from 'axios';
import helpers from '../../mixins/helpers';
/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint dot-notation: 0 */
/* eslint no-underscore-dangle: 0 */

const sharePointListUrl = `${process.env.VUE_APP_ROOT_URI}/_api/lists/getbytitle('blog')/items`;
const listFields = 'ID,Title,SeoTitle,BlogCategory,BlogSubCategory,BlogUrlCategory,SeoDescription,Blurb,BlogSlug,HeaderImage,ArticleStartDate,BlogAuthor';

const config = {
  crossdomain: true,
  // withCredentials: true,
  headers: { 'Access-Control-Allow-Origin': '*', Accept: 'application/json; odata=verbose' },
};

function blogPostNotFound(sRequest) {
  return {
    title: 'Post Not Found',
    category: sRequest.sCategory,
    seodescription: 'Post Not Found',
    url: sRequest.sUrl,
    urlCategory: sRequest.sCategory,
    id: 0,
  };
}

function singleBlogPostTransform(foundPost, context, tfbId) {
  try {
    if (foundPost) {
      let postUrl = `${process.env.VUE_APP_ROOT_URI}/blog`;

      if (foundPost.BlogUrlCategory && foundPost.BlogUrlCategory !== null && foundPost.BlogUrlCategory !== '') {
        postUrl += `/${helpers.methods.kebabify(foundPost.BlogUrlCategory).toLowerCase()}/${helpers.methods.kebabify(foundPost.BlogSlug).toLowerCase()}`;
      } else {
        postUrl += `/${helpers.methods.kebabify(foundPost.BlogCategory).toLowerCase()}`;

        if (foundPost.BlogSubCategory && foundPost.BlogSubCategory !== null && foundPost.BlogSubCategory !== '') {
          postUrl += `/${helpers.methods.kebabify(foundPost.BlogSubCategory).toLowerCase()}/${helpers.methods.kebabify(foundPost.BlogSlug).toLowerCase()}`;
        } else {
          postUrl += `/${helpers.methods.kebabify(foundPost.BlogSlug).toLowerCase()}`;
        }
      }

      const postTags = [];
      let jsonTags = '';

      if (foundPost.TaxKeyword && foundPost.TaxKeyword.results && foundPost.TaxKeyword.results.length > 0) {
        for (let t = 0; t < foundPost.TaxKeyword.results.length; t += 1) {
          postTags.push(foundPost.TaxKeyword.results[t].Label);
          jsonTags += `${foundPost.TaxKeyword.results[t].Label},`;
        }
      }

      return {
        title: foundPost.Title,
        seotitle: foundPost.SeoTitle ? foundPost.SeoTitle : foundPost.Title,
        category: foundPost.BlogCategory,
        subcategory: foundPost.BlogSubCategory,
        tags: postTags,
        author: helpers.methods.replaceAll(foundPost.BlogAuthor, ';', ' and '),
        illustrator: helpers.methods.replaceAll(foundPost.BlogIllustrator, ';', ' and '),
        publishDate: foundPost.ArticleStartDate,
        modifiedDate: foundPost.Modified,
        headerImage: foundPost.HeaderImage.Url.toLowerCase(),
        blurb: foundPost.Blurb,
        seodescription: foundPost.SeoDescription ? foundPost.SeoDescription : foundPost.Blurb,
        url: foundPost.BlogSlug.toLowerCase(),
        urlCategory: foundPost.BlogUrlCategory && foundPost.BlogUrlCategory !== null && foundPost.BlogUrlCategory !== '' ? foundPost.BlogUrlCategory : foundPost.BlogCategory,
        fullUrl: postUrl,
        text: foundPost.BlogText,
        id: foundPost.ID,
        jsonld: {
          '@context': 'https://schema.org',
          '@type': 'NewsArticle',
          headline: foundPost.Title,
          image: foundPost.HeaderImage.Url.toLowerCase(),
          datePublished: foundPost.ArticleStartDate,
          dateModified: foundPost.Modified,
          abstract: foundPost.SeoDescription ? foundPost.SeoDescription : foundPost.Blurb,
          articleSection: foundPost.BlogCategory,
          articleBody: foundPost.BlogText,
          author: helpers.methods.replaceAll(foundPost.BlogAuthor, ';', ' and'),
          url: postUrl,
          mainEntityOfPage: postUrl,
          publisher: {
            '@type': 'Organization',
            name: 'Texas Farm Bureau Insurance',
            logo: `${process.env.VUE_APP_ROOT_URI}/publishingimages/tfb-logo.jpg`,
          },
          keywords: jsonTags,
        },
      };
    }

    return null;
  } catch (error) {
    context.dispatch('app/logToULS', {
      logLevel: 'Error', logMessage: error, fileName: 'blog.js', methodName: 'singleBlogPostTransform', trackerId: tfbId,
    }, { root: true });
    return null;
  }
}

function singleBlogPostNoJsonTransform(foundPost, context, tfbId) {
  try {
    if (foundPost) {
      let postUrl = `${process.env.VUE_APP_ROOT_URI}/blog`;

      if (foundPost.BlogUrlCategory && foundPost.BlogUrlCategory !== null && foundPost.BlogUrlCategory !== '') {
        postUrl += `/${helpers.methods.kebabify(foundPost.BlogUrlCategory).toLowerCase()}/${helpers.methods.kebabify(foundPost.BlogSlug).toLowerCase()}`;
      } else {
        postUrl += `/${helpers.methods.kebabify(foundPost.BlogCategory).toLowerCase()}`;

        if (foundPost.BlogSubCategory && foundPost.BlogSubCategory !== null && foundPost.BlogSubCategory !== '') {
          postUrl += `/${helpers.methods.kebabify(foundPost.BlogSubCategory).toLowerCase()}/${helpers.methods.kebabify(foundPost.BlogSlug).toLowerCase()}`;
        } else {
          postUrl += `/${helpers.methods.kebabify(foundPost.BlogSlug).toLowerCase()}`;
        }
      }

      return {
        title: foundPost.Title,
        seotitle: foundPost.SeoTitle ? foundPost.SeoTitle : foundPost.Title,
        category: foundPost.BlogCategory,
        subcategory: foundPost.BlogSubCategory,
        headerImage: foundPost.HeaderImage.Url.toLowerCase(),
        blurb: foundPost.Blurb,
        seodescription: foundPost.SeoDescription ? foundPost.SeoDescription : foundPost.Blurb,
        url: foundPost.BlogSlug.toLowerCase(),
        urlCategory: foundPost.BlogUrlCategory && foundPost.BlogUrlCategory !== null && foundPost.BlogUrlCategory !== '' ? foundPost.BlogUrlCategory : foundPost.BlogCategory,
        fullUrl: postUrl,
        text: foundPost.BlogText,
        id: foundPost.ID,
      };
    }

    return null;
  } catch (error) {
    context.dispatch('app/logToULS', {
      logLevel: 'Error', logMessage: error, fileName: 'blog.js', methodName: 'singleBlogPostTransform', trackerId: tfbId,
    }, { root: true });
    return null;
  }
}

function blogPostsTransform(foundPosts, context, tfbId) {
  try {
    const returnPosts = [];

    if (foundPosts) {
      for (let i = 0; i < foundPosts.length; i += 1) {
        const responsePost = foundPosts[i];
        let postUrl = `${process.env.VUE_APP_ROOT_URI}/blog`;

        if (responsePost.BlogUrlCategory && responsePost.BlogUrlCategory !== null && responsePost.BlogUrlCategory !== '') {
          postUrl += `/${helpers.methods.kebabify(responsePost.BlogUrlCategory).toLowerCase()}/${helpers.methods.kebabify(responsePost.BlogSlug).toLowerCase()}`;
        } else {
          postUrl += `/${helpers.methods.kebabify(responsePost.BlogCategory).toLowerCase()}`;

          if (responsePost.BlogSubCategory && responsePost.BlogSubCategory !== null && responsePost.BlogSubCategory !== '') {
            postUrl += `/${helpers.methods.kebabify(responsePost.BlogSubCategory).toLowerCase()}/${helpers.methods.kebabify(responsePost.BlogSlug).toLowerCase()}`;
          } else {
            postUrl += `/${helpers.methods.kebabify(responsePost.BlogSlug).toLowerCase()}`;
          }
        }

        const builtPost = {
          title: responsePost.Title,
          seotitle: responsePost.SeoTitle ? responsePost.SeoTitle : responsePost.Title,
          category: responsePost.BlogCategory,
          subcategory: responsePost.BlogSubCategory,
          headerImage: responsePost.HeaderImage.Url.toLowerCase(),
          blurb: responsePost.Blurb,
          url: responsePost.BlogSlug.toLowerCase(),
          urlCategory: responsePost.BlogUrlCategory && responsePost.BlogUrlCategory !== null && responsePost.BlogUrlCategory !== '' ? responsePost.BlogUrlCategory : responsePost.BlogCategory,
          fullUrl: postUrl,
          id: responsePost.ID,
          jsonld: {
            '@context': 'https://schema.org',
            '@type': 'NewsArticle',
            headline: responsePost.Title,
            image: responsePost.HeaderImage.Url.toLowerCase(),
            datePublished: responsePost.ArticleStartDate,
            abstract: responsePost.SeoDescription ? responsePost.SeoDescription : responsePost.Blurb,
            articleSection: responsePost.BlogCategory,
            author: helpers.methods.replaceAll(responsePost.BlogAuthor, ';', ' and'),
            url: postUrl,
            publisher: {
              '@type': 'Organization',
              name: 'Texas Farm Bureau Insurance',
              logo: `${process.env.VUE_APP_ROOT_URI}/publishingimages/tfb-logo.jpg`,
            },
          },
        };

        returnPosts.push(builtPost);
      }
    }

    return returnPosts;
  } catch (error) {
    context.dispatch('app/logToULS', {
      logLevel: 'Error', logMessage: error, fileName: 'blog.js', methodName: 'blogPostsTransform', trackerId: tfbId,
    }, { root: true });
    return null;
  }
}

function blogPostsNoJsonTransform(foundPosts, context, tfbId) {
  try {
    const returnPosts = [];

    if (foundPosts) {
      for (let i = 0; i < foundPosts.length; i += 1) {
        const responsePost = foundPosts[i];
        let postUrl = `${process.env.VUE_APP_ROOT_URI}/blog`;

        if (responsePost.BlogUrlCategory && responsePost.BlogUrlCategory !== null && responsePost.BlogUrlCategory !== '') {
          postUrl += `/${helpers.methods.kebabify(responsePost.BlogUrlCategory).toLowerCase()}/${helpers.methods.kebabify(responsePost.BlogSlug).toLowerCase()}`;
        } else {
          postUrl += `/${helpers.methods.kebabify(responsePost.BlogCategory).toLowerCase()}`;

          if (responsePost.BlogSubCategory && responsePost.BlogSubCategory !== null && responsePost.BlogSubCategory !== '') {
            postUrl += `/${helpers.methods.kebabify(responsePost.BlogSubCategory).toLowerCase()}/${helpers.methods.kebabify(responsePost.BlogSlug).toLowerCase()}`;
          } else {
            postUrl += `/${helpers.methods.kebabify(responsePost.BlogSlug).toLowerCase()}`;
          }
        }

        returnPosts.push({
          title: responsePost.Title,
          seotitle: responsePost.SeoTitle ? responsePost.SeoTitle : responsePost.Title,
          category: responsePost.BlogCategory,
          subcategory: responsePost.BlogSubCategory,
          headerImage: responsePost.HeaderImage.Url.toLowerCase(),
          blurb: responsePost.Blurb,
          url: responsePost.BlogSlug.toLowerCase(),
          urlCategory: responsePost.BlogUrlCategory && responsePost.BlogUrlCategory !== null && responsePost.BlogUrlCategory !== '' ? responsePost.BlogUrlCategory : responsePost.BlogCategory,
          fullUrl: postUrl,
          id: responsePost.ID,
        });
      }
    }

    return returnPosts;
  } catch (error) {
    context.dispatch('app/logToULS', {
      logLevel: 'Error', logMessage: error, fileName: 'blog.js', methodName: 'blogPostsNoJsonTransform', trackerId: tfbId,
    }, { root: true });
    return null;
  }
}

function blogPostsFeedTransform(foundPosts, context, tfbId) {
  try {
    const rowOne = [];
    let rowTwo = [];
    const rowThree = [];
    let rowFour = [];

    if (foundPosts) {
      for (let i = 0; i < foundPosts.length; i += 1) {
        const responsePost = foundPosts[i];
        let postUrl = `${process.env.VUE_APP_ROOT_URI}/blog`;

        if (responsePost.BlogUrlCategory && responsePost.BlogUrlCategory !== null && responsePost.BlogUrlCategory !== '') {
          postUrl += `/${helpers.methods.kebabify(responsePost.BlogUrlCategory).toLowerCase()}/${helpers.methods.kebabify(responsePost.BlogSlug).toLowerCase()}`;
        } else {
          postUrl += `/${helpers.methods.kebabify(responsePost.BlogCategory).toLowerCase()}`;

          if (responsePost.BlogSubCategory && responsePost.BlogSubCategory !== null && responsePost.BlogSubCategory !== '') {
            postUrl += `/${helpers.methods.kebabify(responsePost.BlogSubCategory).toLowerCase()}/${helpers.methods.kebabify(responsePost.BlogSlug).toLowerCase()}`;
          } else {
            postUrl += `/${helpers.methods.kebabify(responsePost.BlogSlug).toLowerCase()}`;
          }
        }

        const builtPost = {
          title: responsePost.Title,
          seotitle: responsePost.SeoTitle ? responsePost.SeoTitle : responsePost.Title,
          category: responsePost.BlogCategory,
          subcategory: responsePost.BlogSubCategory,
          headerImage: responsePost.HeaderImage.Url.toLowerCase(),
          blurb: responsePost.Blurb,
          url: responsePost.BlogSlug.toLowerCase(),
          urlCategory: responsePost.BlogUrlCategory && responsePost.BlogUrlCategory !== null && responsePost.BlogUrlCategory !== '' ? responsePost.BlogUrlCategory : responsePost.BlogCategory,
          fullUrl: postUrl,
          id: responsePost.ID,
          jsonld: {
            '@context': 'https://schema.org',
            '@type': 'NewsArticle',
            headline: responsePost.Title,
            image: responsePost.HeaderImage.Url.toLowerCase(),
            datePublished: responsePost.ArticleStartDate,
            abstract: responsePost.SeoDescription ? responsePost.SeoDescription : responsePost.Blurb,
            articleSection: responsePost.BlogCategory,
            author: helpers.methods.replaceAll(responsePost.BlogAuthor, ';', ' and'),
            url: postUrl,
            publisher: {
              '@type': 'Organization',
              name: 'Texas Farm Bureau Insurance',
              logo: `${process.env.VUE_APP_ROOT_URI}/publishingimages/tfb-logo.jpg`,
            },
          },
        };

        if (i < 3) {
          rowOne.push(builtPost);
        } else if (i < 8) {
          rowTwo.push(builtPost);
        } else if (i < 11) {
          rowThree.push(builtPost);
        } else {
          rowFour.push(builtPost);
        }
      }

      if (rowOne.length > 0) {
        const rowId = context.getters['postRows'].length + 1;
        context.commit('ADD_POST_ROWS', { id: rowId, rowType: 1, posts: rowOne });
      }
      if (rowTwo.length > 0) {
        const rowId = context.getters['postRows'].length + 1;

        if (rowTwo.length > 4) {
          const rowTwoA = [];
          const rowTwoB = rowTwo[rowTwo.length - 1];
          for (let p = 0; p < rowTwo.length; p += 1) {
            if (p < rowTwo.length - 1) {
              rowTwoA.push(rowTwo[p]);
            }
          }

          rowTwo = { singlePost: rowTwoB, postArray: rowTwoA };
          context.commit('ADD_POST_ROWS', { id: rowId, rowType: 2, posts: rowTwo });
        } else {
          context.commit('ADD_POST_ROWS', { id: rowId, rowType: 1, posts: rowTwo });
        }
      }
      if (rowThree.length > 0) {
        const rowId = context.getters['postRows'].length + 1;
        context.commit('ADD_POST_ROWS', { id: rowId, rowType: 1, posts: rowThree });
      }
      if (rowFour.length > 0) {
        const rowId = context.getters['postRows'].length + 1;

        if (rowFour.length > 4) {
          const rowFourA = [];
          const rowFourB = rowFour[rowFour.length - 1];
          for (let p = 0; p < rowFour.length; p += 1) {
            if (p < rowFour.length - 1) {
              rowFourA.push(rowFour[p]);
            }
          }

          rowFour = { singlePost: rowFourB, postArray: rowFourA };
          context.commit('ADD_POST_ROWS', { id: rowId, rowType: 3, posts: rowFour });
        } else {
          context.commit('ADD_POST_ROWS', { id: rowId, rowType: 1, posts: rowFour });
        }
      }
    }
  } catch (error) {
    context.dispatch('app/logToULS', {
      logLevel: 'Error', logMessage: error, fileName: 'blog.js', methodName: 'blogPostsFeedTransform', trackerId: tfbId,
    }, { root: true });
  }
}

function getDefaultState() {
  return {
    postRows: [],
    nextPage: null,
    post: {
      title: '',
      category: '',
      subcategory: '',
      tags: null,
      author: '',
      illustrator: '',
      publishDate: '',
      modifiedDate: '',
      headerImage: '',
      blurb: '',
      seodescription: '',
      url: '',
      fullUrl: '',
      text: '',
      id: '',
    },
  };
}

const state = getDefaultState();

const actions = {
  getPostListing(context, sRequest) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${sharePointListUrl}?$select=${listFields}&$orderby=ArticleStartDate desc&$top=${sRequest.sTop}`, config)
        .then((response) => {
          if (response && response.data && response.data.d) {
            if (response.data.d.__next) {
              context.commit('SET_NEXT_PAGE', helpers.methods.replaceAll(response.data.d.__next, '&amp;', '&'));
            }

            if (response.data.d.results && response.data.d.results.length > 0) {
              resolve(blogPostsTransform(response.data.d.results, context, sRequest.sTfbId));
            }
          }

          resolve(null);
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'blog.js', methodName: 'getPostListing-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getMorePostListing(context, sRequest) {
    return new Promise((resolve, reject) => {
      if (context.state.nextPage !== null) {
        axios
          .get(context.state.nextPage, config)
          .then((response) => {
            if (response && response.data && response.data.d) {
              if (response.data.d.__next) {
                context.commit('SET_NEXT_PAGE', helpers.methods.replaceAll(response.data.d.__next, '&amp;', '&'));
              } else {
                context.commit('SET_NEXT_PAGE', null);
              }

              if (response.data.d.results && response.data.d.results.length > 0) {
                resolve(blogPostsTransform(response.data.d.results, context, sRequest.sTfbId));
              }
            }

            resolve(null);
          })
          .catch((error) => {
            context.dispatch('app/logToULS', {
              logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'blog.js', methodName: 'getMorePostListing-Error', trackerId: sRequest.sTfbId,
            }, { root: true });
            reject(error);
          });
      } else {
        resolve(null);
      }
    });
  },
  getFeedPosts(context, sRequest) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${sharePointListUrl}?$select=${listFields}&$orderby=ArticleStartDate desc&$top=${sRequest.sTop}`, config)
        .then((response) => {
          if (response && response.data && response.data.d) {
            if (response.data.d.results && response.data.d.results.length > 0) {
              blogPostsFeedTransform(response.data.d.results, context, sRequest.sTfbId);
            }

            if (response.data.d.__next) {
              context.commit('SET_NEXT_PAGE', helpers.methods.replaceAll(response.data.d.__next, '&amp;', '&'));
              resolve(true);
            }
          }

          resolve(false);
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'blog.js', methodName: 'getFeedPosts-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getNextFeedPosts(context, sRequest) {
    return new Promise((resolve, reject) => {
      axios
        .get(context.state.nextPage, config)
        .then((response) => {
          if (response && response.data && response.data.d) {
            if (response.data.d.results && response.data.d.results.length > 0) {
              blogPostsFeedTransform(response.data.d.results, context, sRequest.sTfbId);
            }

            if (response.data.d.__next) {
              context.commit('SET_NEXT_PAGE', helpers.methods.replaceAll(response.data.d.__next, '&amp;', '&'));
              resolve(true);
            }
          }

          resolve(false);
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'blog.js', methodName: 'getNextFeedPosts-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getFeaturedPosts(context, sRequest) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${sharePointListUrl}?$select=${listFields}&$filter=(Featured eq 1)&$orderby=ArticleStartDate desc&$top=3`,
          config)
        .then((response) => {
          const foundPosts = [];
          if (response && response.data && response.data.d && response.data.d.results && response.data.d.results.length > 0) {
            resolve(blogPostsTransform(response.data.d.results, context, sRequest.sTfbId));
          }

          resolve(foundPosts);
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'blog.js', methodName: 'getFeaturedPosts-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getNextPost(context, sRequest) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${sharePointListUrl}?$select=${listFields}&$filter=((ID gt '${sRequest.sId}') and (ArticleStartDate ge '${sRequest.sDate}'))&$top=1`, config)
        .then((response) => {
          if (response && response.data && response.data.d && response.data.d.results && response.data.d.results.length > 0) {
            resolve(singleBlogPostNoJsonTransform(response.data.d.results[0], context, sRequest.sTfbId));
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'blog.js', methodName: 'getNextPost-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getPreviousPost(context, sRequest) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${sharePointListUrl}?$select=${listFields}&$filter=((ID lt '${sRequest.sId}') and (ArticleStartDate le '${sRequest.sDate}'))&$orderby=ArticleStartDate desc&$top=1`, config)
        .then((response) => {
          if (response && response.data && response.data.d && response.data.d.results && response.data.d.results.length > 0) {
            resolve(singleBlogPostNoJsonTransform(response.data.d.results[0], context, sRequest.sTfbId));
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'blog.js', methodName: 'getPreviousPost-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getRelatedPosts(context, sRequest) {
    let buildUrl = `${sharePointListUrl}?$select=${listFields}&$filter=((BlogSubCategory eq '${sRequest.sSubCategory}' or BlogCategory eq '${sRequest.sCategory}')`;

    if (sRequest.sExcludeId) {
      buildUrl += ` and (ID ne '${sRequest.sExcludeId}')`;
    }

    if (sRequest.sDirection) {
      buildUrl += `)&$orderby=ArticleStartDate ${sRequest.sDirection}`;
    } else {
      buildUrl += ')&$orderby=ArticleStartDate desc';
    }

    buildUrl += '&$top=3';

    return new Promise((resolve, reject) => {
      axios
        .get(buildUrl, config)
        .then((response) => {
          const foundPosts = [];
          if (response && response.data && response.data.d && response.data.d.results && response.data.d.results.length > 0) {
            resolve(blogPostsNoJsonTransform(response.data.d.results, context, sRequest.sTfbId));
          }

          resolve(foundPosts);
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'blog.js', methodName: 'getRelatedPosts-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getFeedPostsByCategory(context, sRequest) {
    let buildUrl = `${sharePointListUrl}?$select=${listFields}&$filter=(BlogCategory eq '${sRequest.sCategory}' or BlogSubCategory eq '${sRequest.sCategory}'`;

    if (sRequest.sExcludeId) {
      buildUrl += ` and (ID ne '${sRequest.sExcludeId}')`;
    }

    buildUrl += ')&$orderby=ArticleStartDate desc';

    if (sRequest.sTop) {
      buildUrl += `&$top=${sRequest.sTop}`;
    } else {
      buildUrl += '&$top=16';
    }

    return new Promise((resolve, reject) => {
      axios
        .get(buildUrl, config)
        .then((response) => {
          if (response && response.data && response.data.d) {
            if (response.data.d.results && response.data.d.results.length > 0) {
              blogPostsFeedTransform(response.data.d.results, context, sRequest.sTfbId);
            }

            if (response.data.d.__next) {
              context.commit('SET_NEXT_PAGE', helpers.methods.replaceAll(response.data.d.__next, '&amp;', '&'));
              resolve(true);
            }
          }

          resolve(false);
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'blog.js', methodName: 'getFeedPostsByCategory-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getPostsByTag(context, sRequest) {
    let buildUrl = `${sharePointListUrl}?$select=${listFields}&$filter=(substringof('${helpers.methods.replaceAll(sRequest.sKeyword, '\'', '')}',TaxCatchAll/Term))`;

    if (sRequest.sTop) {
      buildUrl += `&$top=${sRequest.sTop}`;
    } else {
      buildUrl += '&$top=16';
    }

    buildUrl += '&$orderby=ArticleStartDate desc';

    return new Promise((resolve, reject) => {
      axios
        .get(buildUrl, config)
        .then((response) => {
          if (response && response.data && response.data.d && response.data.d.results && response.data.d.results.length > 0) {
            resolve(blogPostsTransform(response.data.d.results, context, sRequest.sTfbId));
          }

          resolve([]);
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'blog.js', methodName: 'getPostsByTags-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getPostsByTags(context, sRequest) {
    let buildUrl = `${sharePointListUrl}?$select=${listFields}&$filter=`;

    for (let k = 0; k < sRequest.sKeywords.length; k += 1) {
      const keyword = sRequest.sKeywords[k];
      if (k !== 0) {
        buildUrl += ' or ';
      }

      buildUrl += `(substringof('${helpers.methods.replaceAll(keyword, '\'', '')}',TaxCatchAll/Term))`;
    }

    if (sRequest.sTop) {
      buildUrl += `&$top=${sRequest.sTop}`;
    } else {
      buildUrl += '&$top=16';
    }

    buildUrl += '&$orderby=ArticleStartDate desc';

    return new Promise((resolve, reject) => {
      axios
        .get(buildUrl, config)
        .then((response) => {
          if (response && response.data && response.data.d && response.data.d.results && response.data.d.results.length > 0) {
            resolve(blogPostsTransform(response.data.d.results, context, sRequest.sTfbId));
          }

          resolve([]);
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'blog.js', methodName: 'getPostsByTags-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getFeedPostsByAuthor(context, sRequest) {
    let buildUrl = `${sharePointListUrl}?$select=${listFields}&$filter=(BlogAuthor eq '${sRequest.sAuthorName}')&$orderby=ArticleStartDate desc`;

    if (sRequest.sTop) {
      buildUrl += `&$top=${sRequest.sTop}`;
    } else {
      buildUrl += '&$top=16';
    }
    return new Promise((resolve, reject) => {
      axios
        .get(buildUrl, config)
        .then((response) => {
          if (response && response.data && response.data.d) {
            if (response.data.d.results && response.data.d.results.length > 0) {
              blogPostsFeedTransform(response.data.d.results, context, sRequest.sTfbId);
            }

            if (response.data.d.__next) {
              context.commit('SET_NEXT_PAGE', helpers.methods.replaceAll(response.data.d.__next, '&amp;', '&'));
              resolve(true);
            }
          }

          resolve(false);
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'blog.js', methodName: 'getPostsByAuthor-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getPostById(context, sRequest) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${sharePointListUrl}?$select=*&$filter=(ID eq '${sRequest.sId}')`, config)
        .then((response) => {
          let foundPost = { id: 0 };
          if (response && response.data && response.data.d && response.data.d.results && response.data.d.results.length > 0) {
            foundPost = singleBlogPostTransform(response.data.d.results[0], context, sRequest.sTfbId);
          } else {
            foundPost = blogPostNotFound(sRequest);
          }

          resolve(foundPost);
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'blog.js', methodName: 'getPostById-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
  getPostByUrl(context, sRequest) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${sharePointListUrl}?$select=*&$filter=BlogSlug eq '${sRequest.sUrl}'&$orderby=ArticleStartDate desc`, config)
        .then((response) => {
          let foundPost = { id: 0 };
          if (response && response.data && response.data.d && response.data.d.results && response.data.d.results.length > 0) {
            foundPost = singleBlogPostTransform(response.data.d.results[0], context, sRequest.sTfbId);
          } else {
            foundPost = blogPostNotFound(sRequest);
          }

          resolve(foundPost);
        })
        .catch((error) => {
          context.dispatch('app/logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'blog.js', methodName: 'getPostByUrl-Error', trackerId: sRequest.sTfbId,
          }, { root: true });
          reject(error);
        });
    });
  },
};

const mutations = {
  ...make.mutations(state),
  ADD_POST_ROWS(state, value) {
    state.postRows.push(value);
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

const getters = {
  ...make.getters(state),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
