<template>
  <div id="main-content">
    <v-overlay :value="pageLoading">
      <v-progress-circular indeterminate
                           size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="documentLoading"
              hide-overlay
              persistent
              width="300">
      <v-card :color="loadingColor"
              dark>
        <v-card-text>
          <br/>
          Document opened in new window. If you do not see the document,
           ensure that you have your pop-up blocker turned off, or allow
           pop-ups from this website.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="white"
                 text
                 @click="documentLoading = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <error-message />
    <router-view></router-view>
    <scroll-to-top />
    <live-chat />
  </div>
</template>
<script>
  import { sync } from 'vuex-pathify';
  import ScrollToTop from './components/ScrollToTop.vue';
  import LiveChat from './components/LiveChat.vue';

  export default {
    name: 'MainContent',
    components: {
      ScrollToTop,
      LiveChat,
    },
    computed: {
      pageLoading() {
        return this.$store.state.app.isLoading;
      },
      documentLoading: sync('app/isDocumentLoading'),
      loadingColor() {
        return '#A40013';
      },
    },
  };
</script>
